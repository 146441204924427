export const gsapAnimations = () => {
  gsap.registerPlugin(ScrollTrigger);

  // HOMEPAGE
  if (document.querySelector('.home')) {
    gsap.from('.page-title', {
      x: -75,
      opacity: 0,
      duration: 1,
      delay: 1,
      ease: 'power1'
    })
    gsap.from('.featured-image .image', {
      scale: 1.2,
      duration: 1,
      delay: 1,
      ease: 'power1'
    })

    gsap.from('.services .service', {
      x: -50,
      opacity: 0,
      duration: 0.5,
      ease: 'power1',
      stagger: 0.2,
      scrollTrigger: {
        trigger: '#service-icons',
        start: 'center bottom',
        // markers: true
      }
    })
  }
}